.top-flags {
            position: absolute;
            top: -23px;
            left: 0;
            background-color: var(--white);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;
            padding-left: 10px;
            display: flex;
            align-items: center;

            .flag-sponsored {
                color: var(--black);
                font-size: 12px;
                display: flex;
                align-items: center;

                .state-info-button {
                    background-color: transparent;
                    border: none;
                    color: var(--black);
                    cursor: pointer;
                }

                .flag-sponsored_img {
                    margin-top: 5px;
                }
            }
        }

        .sponsored-modal {
            .modal-title {
                color: var(--blue-pressed);
                font-family: var(--font-bold);
            }

            .modal_content {
                .custom_list {
                    padding: 0;
                    margin-bottom: 13px;

                    &-item {
                        position: relative;
                        padding-left: 20px;
                        margin-bottom: 5px;

                        &::before {
                            content: "•";
                            color: var(--blue-pressed);
                            font-size: 30px;
                            position: absolute;
                            left: 0;
                            top: -10px;
                        }
                    }
                }

                &-top {
                    font-size: 12px;

                    .font-bold {
                        font-size: 14px;
                        margin-bottom: 25px;

                        &:nth-child(3) {
                            font-size: 12px;
                        }
                    }

                    .font-bold-small {
                        margin-bottom: 25px;
                    }

                    .paragraphe {
                        margin-bottom: 25px;
                    }
                }

                &-bottom {
                    #ot-sdk-btn {
                        font-size: 14px;
                        color: var(--blue-pressed) !important;/* stylelint-disable-line declaration-no-important */
                        background: var(--white);
                    }
                }
            }
        }