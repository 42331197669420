@cardMargin: 16px;
@cardPadding: 16px;

.bloc-custom-banner {
    margin: 24px 0;

    .wrapper {
        display: flex;
        position: relative;
        height: 230px;
        padding: 16px;

        &.is-link { text-decoration: none; }

        &.is-with-card {
            padding: 0;
            display: block;
        }
    }

    .image {
        height: 100%;
        width: 100%;
        margin: auto;
        object-fit: contain;

        &.lifestyle {
            width: 100%;
        }
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: @cardPadding;
        margin: @cardMargin @cardMargin @cardMargin 0;
        width: 100%;
        min-width: 310px;
        max-width: 496px;
        background-color: var(--white);
        border-radius: 3px;

        @media @desktop {
            max-width: 624px;
        }

        @media @desktopXL {
            max-width: 624px;
        }
    }

    .cta {
        background-color: var(--red);
        color: var(--white);
        border-radius: 3px;
        padding: 11px 18px;
        min-width: 208px;
        text-align: center;
        margin: auto 0 0 auto;
        text-decoration: none;
    }

    .link {
        width: 100%;
    }

    .cta,
    .link {
        &::before {
            content: "";
            position: absolute;
            z-index: 2;
            inset: 0;
        }
    }

    .wrapper--is-image-banner .cta {
        position: absolute;
        bottom: 16px;
        right: 16px;
        z-index: 1;
    }

    .arrow {
        width: auto;
        position: absolute;
        right: @cardPadding;
        bottom: @cardPadding;
    }

    .image-wrapper {
        min-height: 213px;
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .image { position: relative; }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            aspect-ratio: 1;
            height: 0;
            width: 0;
            box-shadow: 0 0 90px 50px var(--white);
            border-radius: 50%;
        }
    }

    &.is-lifestyle {
        .wrapper { padding: 0; }

        .image { object-fit: cover; }

        .is-with-card .image {
            object-fit: contain;
            object-position: left;
        }

        .image-wrapper::before { display: none; }
    }

    .details {
        &__header {
            font-family: var(--font-bold);
            color: var(--red);
            font-size: 12px;
        }

        &__content {
            color: var(--dark-grey);
            font-size: 12px;
            line-height: 15px;

            &--is-bold {
                font-family: var(--font-bold);
            }
        }
    }

    .product {
        color: var(--dark-grey);

        &__family {
            color: var(--dark-grey);
            font-size: 14px;
        }

        &__reference {
            font-family: var(--font-bold);
            color: var(--grey);
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 4px;
        }

        &__description {
            font-size: 14px;
            line-height: 18px;
        }

        &__discount-offer {
            font-family: var(--font-bold);
            color: var(--red);
            font-size: 24px;
        }
    }

    .banner-with-card {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: flex-end;
    }

    .legal-mentions {
        color: var(--mid-grey);
        font-size: 8px;
        position: absolute;
        bottom: 5px;
        right: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 90%;
    }
}

.header {
    &__wrapper {
        margin-bottom: 8px;
    }

    &__image {
        width: auto;
        max-height: 40px;
    }

    &__link {
        text-decoration: none;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
        }
    }

    &__wrapper--with-picto {
        display: flex;
        align-items: center;

        .header__image {
            margin-right: 10px;
            height: auto;
            width: auto;
            max-height: 40px;
            max-width: 60px;
        }
    }

    &__date-mention {
        color: var(--dark-grey);
        font-size: 12px;
        text-decoration: underline;
    }

    &__title {
        font-family: var(--font-bold);
        font-size: 24px;
        line-height: 31px;
        color: var(--grey);
    }

    &__subTitle {
        font-family: var(--font-bold);
        font-size: 12px;
        color: var(--dark-grey);
    }

    .price {
        font-family: var(--font-bold);
        font-size: 24px;
        margin-right: 16px;

        &_wrapper {
            display: flex;
            align-items: flex-end;
        }

        &.promo {
            color: var(--red);
        }

        &.striped {
            color: var(--mid-grey);
            font-size: 18px;
            text-decoration: line-through;
        }
    }
}

.splide__slide .header__image {
    width: auto;
}

.center-info {
    display: flex;
    flex-direction: column;

    &--is-reverted {
        flex-direction: column-reverse;
    }

    .discount-price {
        color: var(--red);
        font-size: 20px;
        font-family: var(--font-bold);
        line-height: 27px;
    }

    .second-text {
        color: var(--dark-grey);
        font-size: 16px;
        line-height: 20px;
    }
}

.service__text {
    color: var(--dark-grey);
    font-size: 12px;
}

.multi_banner .bloc-custom-banner {
    text-align: left;

    .legal-mentions {
        left: 16px;
        right: auto;
    }

    .card {
        min-width: 279px;
        max-width: 279px;

        @media @desktop {
            max-width: 310px;
        }

        @media @desktopXL {
            max-width: 390px;
        }
    }

    .image-wrapper .image {
        height: 100%;
        width: 100%;
    }
}

.multi_banner .bloc-custom-banner .wrapper {
    max-height: 228px;

    .image {
        height: 100%;
        width: 100%;
        margin: auto;
    }
}
