.bloc-banner {
    margin: 24px 0;
    text-align: center;

    .link {
        display: block;
    }

    .image {
        display: block;
        width: 100%;
        height: auto;
    }
}
