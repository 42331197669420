.bloc-rich-banner {
    margin: 24px 0;
    position: relative;

    .wrapper {
        display: flex;
        padding: 16px;
        align-items: center;
    }

    .link {
        text-decoration: none;
        color: var(--dark-grey);

        &::before {
            content: "";
            position: absolute;
            inset: 0;
        }
    }

    .banner-text {
        display: flex;
        height: 100%;
        width: 50%;
        padding-right: 16px;
        justify-content: center;
        flex-direction: column;
        font-family: var(--font-bold);

        .title {
            font-size: 28px;
            margin-bottom: 1rem;

            @media @desktop { font-size: 32px; }

            @media @desktopXL { font-size: 36px; }
        }

        .text {
            line-height: 28px;
            font-size: 16px;

            @media @desktop { font-size: 22px; }
        }
    }

    .banner-image {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
    }

    .banner-image-container {
        overflow: hidden;
        height: 267px;
        width: 50%;

        @media @desktop { height: 357px; }
    }
}
