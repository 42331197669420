.bloc-mosaic {
    margin: 20px auto;

    .wrapper {
        height: 450px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 450px;
        gap: 16px;
    }

    .item {
        position: relative;
        width: 488px;
        padding: 16px;
        color: inherit;
        text-decoration: none;
        overflow: hidden;

        @media @desktop { width: 616px; }

        @media @desktopXL { width: 776px; }
    }

    .main {
        display: flex;
        flex-flow: column;
        flex: 1;
    }

    .picture {
        display: block;
        margin: auto;
        position: relative;
    }

    .picture-link {
        width: 100%;
    }

    .arrow {
        position: absolute;
        bottom: 16px;
        right: 16px;
        pointer-events: none;
    }

    .details {
        display: flex;
        flex-flow: column;
        width: 100%;
        margin-top: auto;
        background-color: var(--white);
        border-radius: 3px;
        padding: 16px;
        z-index: 2;

        .arrow {
            bottom: 32px;
            right: 32px;
        }
    }

    .title,
    .category,
    .name,
    .hook,
    .mention,
    .commercial,
    .service .label {
        line-height: 1.3;
    }

    .exclusive,
    .service,
    .mention {
        margin-top: 4px;
        line-height: 28px;
    }

    .title {
        font-family: var(--font-bold);
        font-size: 20px;
        text-decoration: none;
        color: var(--dark-grey);

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 1;
        }
    }

    .category {
        font-size: 16px;
    }

    .name {
        font-family: var(--font-bold);
        font-size: 16px;
    }

    .hook {
        font-size: 14px;
    }

    .sub {
        margin-top: auto;
    }

    .price {
        font-family: var(--font-bold);
        font-size: 28px;
        margin-bottom: 4px;

        &.promo {
            color: var(--red);
        }
    }

    .price.striped {
        font-size: 14px;
        color: var(--mid-grey);
        text-decoration: line-through;
        margin: 4px 0;
        font-family: var(--font-regular);
    }

    .mention {
        font-size: 16px;
        color: var(--black);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 22px;
    }

    .commercial {
        font-family: var(--font-bold);
        font-size: 24px;
        color: var(--red);
        margin: auto 0;
    }

    .exclusive {
        font-size: 16px;

        .darty {
            font-family: var(--font-bold);
            color: var(--red);
        }
    }

    .service {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding-right: 22px;
        font-size: 16px;
        color: var(--black);

        &::before {
            content: "";
            display: block;
            flex: none;
            height: 28px;
            width: 28px;
            margin-right: 12px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .label {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.conseils {
            &::before {
                background-image: url(../../../version_common/styles/images/pictos/conseil.svg);
            }
        }

        &.garantie {
            &::before {
                background-image: url(../../../version_common/styles/images/pictos/darty_umbrella.svg);
            }
        }

        &.livraison {
            &::before {
                background-image: url(../../../version_common/styles/images/pictos/darty_delivery.svg);
            }
        }

        &.retrait {
            &::before {
                background-image: url(../../../version_common/styles/images/pictos/click_and_collect.svg);
            }
        }
    }

    .large {
        display: flex;
        flex-flow: column;

        .details {
            padding: 24px 16px 16px;
        }

        .title,
        .category,
        .name,
        .hook,
        .commercial {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .commercial {
            font-size: 28px;
            padding-top: 14px;

            @media @desktop {
                padding-top: 0;
            }
        }

        .title,
        .name,
        .category {
            margin-bottom: 4px;

            @media @desktop {
                margin-bottom: 8px;
            }
        }

        .title {
            font-size: 28px;
            line-height: 1.2;
            margin-bottom: 14px;

            @media @desktop {
                margin-bottom: 24px;
            }
        }

        .hook {
            margin-top: 8px;

            @media @desktop {
                margin-top: 12px;
            }
        }

        .price_wrapper {
            margin-top: 14px;
            display: flex;
            flex-direction: column;

            @media @desktopXL {
                margin: auto 0;
                flex-direction: row;
                align-items: center;

                .striped-prices {
                    margin-left: 10px;
                }
            }
        }

        @media @desktop {
            flex-flow: row nowrap;

            .details {
                height: 100%;
                width: 50%;
            }
        }
    }

    .small {
        display: flex;
        flex-flow: row nowrap;

        .title,
        .name,
        .category {
            margin-bottom: 4px;
        }

        .details {
            height: 100%;
            width: 50%;
        }

        .title,
        .category,
        .name,
        .hook,
        .commercial {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
        }

        .category {
            display: none;
        }

        .price_wrapper {
            margin-top: auto;
            display: flex;
            align-items: center;

            .striped-prices {
                margin-left: 10px;
            }
        }
    }

    .flex {
        display: flex;
        flex-flow: column;
        gap: 16px;

        .item {
            height: 50%;
            flex-flow: row nowrap;
        }
    }

    .lifestyle {
        padding: 0;

        .picture {
            height: 100%;
            width: auto;
            object-fit: cover;
            object-position: left center;
        }
    }

    .product,
    .marketing {
        &.large {
            .picture {
                width: auto;
                height: auto;
                overflow: auto;
                margin: auto;

                @media @desktop {
                    height: auto;
                    width: 292px;
                    padding-right: 16px;
                    margin-bottom: auto;
                }
            }

            .details {
                flex: 0;

                @media @desktop {
                    flex: unset;
                }
            }
        }

        .image_halo {
            margin: auto auto 16px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 0;
            min-width: 0;
            height: 100%;
            width: auto;

            .picture {
                height: 100%;

                @media @desktop {
                    height: auto;
                }
            }

            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                margin: auto;
                box-shadow: 0 0 110px 55px var(--white);
                width: 0;
                height: 0;
                border-radius: 100px;
                z-index: 0;
            }
        }

        &.lifestyle {
            display: flex;
            justify-content: flex-end;
            align-items: stretch;
            padding: 16px;
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: left;

            .details {
                position: static;
                margin-top: 0;
                height: auto;
            }

            .picture {
                height: 100%;
                width: auto;
                margin: 0 auto 0 0;
            }
        }

        &.small {
            .picture {
                width: auto;
            }

            .image_halo {
                max-width: 50%;
            }

            .image_halo .picture {
                height: 100%;
            }
        }
    }

    // ajustements sur le bloc mosaique avant BF, CMRC-2981
    &.bloc-mosaic--is-restyled .item {

        @detaillargepadding : 25px;
        @detailsmallpadding : 20px;
        @detailmargin: 16px;
        padding: 0;

        .image_halo {
            margin: auto;

            .picture {
                object-fit: contain;
                padding: 0;
                width: 100%;
                height: 100%;
            }
        }

        .commercial {
            margin: 0 0 auto;
            font-family: var(--font-bold);
        }

        .details {
            margin: @detailmargin @detailmargin @detailmargin 0;
            height: auto;
            flex: 0 0 auto;
        }

        .sub {
            .service,
            .mention {
                margin-top: 7px;
                padding-right: 45px;
            }
        }

        .service, .mention {
            font-size: 13px;
        }

        &:not(.small) .sub {
            height: 40px;
        }

        &.lifestyle {

            @media @desktopXL {
                background-position: -40px center;
            }
        }

        &.image .picture-link--with-background-image {
            background-position: center;
        }

        .category {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            padding-bottom: 2px;
            text-transform: uppercase;
            font-size: inherit;
        }

        .text-item {
            margin: 0;
        }

        &.small {
            .details {
                padding: @detailsmallpadding;
                width: 274px;

                @media @desktop {
                    width: 350px;
                }

                @media @desktopXL {
                    width: 372px;
                }
            }

            &.lifestyle {
                background-size: auto;
                background-position: -60px center;

                @media @desktop {
                    background-position: -40px center;
                }
            }

            .text-item + .text-item {
                margin: 5px 0 0;
            }

            .category {
                padding-bottom: 3px;
            }

            .name {
                font-size: 13px;

                @media @desktopXL {
                    font-size: 14px;
                }
            }

            .title {
                font-size: 21px;

                @media @desktop {
                    font-size: 23px;
                }

                @media @desktopXL {
                    font-size: 25px;
                }
            }

            .price {
                font-size: 27px;

                @media @desktop {
                    font-size: 35px;
                }

                &.striped {
                    font-size: 17px;

                    @media @desktop {
                        font-size: 22px;
                    }
                }
            }

            .commercial {
                font-size: 24px;

                @media @desktop {
                    font-size: 27px;
                }
            }
        }

        &.large {
            flex-flow: row nowrap;

            .text-item + .text-item {
                margin: 15px 0 0;
            }

            .details {
                padding: @detaillargepadding;
                width: 254px;
                flex: 0 0 auto;

                @media @desktop {
                    width: 292px;
                }

                @media @desktopXL {
                    width: 372px;
                }
            }

            &.lifestyle {
                background-size: auto;
                background-position: -80px center;

                .details {
                    flex: unset;
                }

                @media @desktop {
                    background-position: -30px center;
                }
            }

            .name {
                font-size: 16px;

                @media @desktopXL {
                    font-size: 18px;
                }
            }

            .title {
                font-size: 23px;

                @media @desktop {
                    font-size: 27px;
                }

                @media @desktopXL {
                    font-size: 30px;
                }
            }

            .price {
                font-size: 35px;

                @media @desktop {
                    font-size: 42px;
                }

                &.striped {
                    font-size: 22px;

                    @media @desktop {
                        font-size: 26px;
                    }
                }
            }

            .commercial {
                font-size: 28px;

                @media @desktop {
                    font-size: 33px;
                }

                @media @desktopXL {
                    font-size: 35px;
                }
            }
        }

        &.large,
        &.small {
            &.lifestyle {

                @media @desktopXL {
                    background-position: 0 center;
                }
            }
        }

        .chevron-cta {
            position: absolute;
        }

        /* stylelint-disable at-rule-empty-line-before */
        &.small .chevron-cta {
            @chevronctaspacing : @detailsmallpadding + @detailmargin;
            right: @chevronctaspacing;
            bottom: @chevronctaspacing;
        }

        &.large .chevron-cta {
            @chevronctaspacing : @detaillargepadding + @detailmargin;
            right: @chevronctaspacing;
            bottom: @chevronctaspacing;
        }

        /* stylelint-enable at-rule-empty-line-before */
    }
}
