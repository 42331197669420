/* Surcharge CSS de la lib Video.js */

.advertisement-media {
    position: relative;
    width: 500px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--white);
    overflow: hidden;

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }

    .video_pub-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.with-background-format {
            position: relative;
            width: auto;
            max-height: 290px;
            height: 100%;
            aspect-ratio: 16 / 9;
        }

        .background-video-canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }


        .video_pub-link {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background:none;
            padding: 0;
            margin: 0;
            border: 0;
        }

        .video_pub-image {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media (min-width: 1280px) {
        .advertisement-media {
            width: 30%;
        }
    }

    .video-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #background-canvas {
        width: 100%;
        height: 100%;
    }

    .background-video {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
    }
}

.advertisement-container {
    .video-js {
        .vjs-play-progress {
            background-color: var(--blue-pressed);
    
            &::before {
                display: none;
            }
        }
    
        .vjs-tech {
            outline: none;
        }
    
        [class*="advertisement-video-"][class$="-dimensions"] {
            background-color: transparent;
        }
    
        .video-load-progress {
            background: var(--low-grey);
        }
    
        .vjs-control-bar {
            background-color: transparent;
        }
    
        .vjs-progress-control {
            .vjs-progress-holder {
                margin: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
    
        .vjs-slider {
            margin: 0;
        }
    
        .vjs-play-control {
            position: absolute;
            bottom: 30px;
            right: 15px;
            color: var(--black);
        }
    
        .vjs-mute-control {
            position: absolute;
            bottom: 30px;
            right: 50px;
            color: var(--black);
        }
    
        .video-play-progress {
            background-color: var(--blue-pressed);
        }
    
        .control-bar {
            position: relative;
            bottom: -240px;
            display: flex;
            justify-content: flex-start;
            background-color: transparent;
            opacity: 1;
            visibility: visible;
    
            &.play-control,
            &.mute-control {
                display: block;
            }
    
            &.fullscreen-control,
            &.volume-menu-button,
            &.captions-button,
            &.remaining-time-display,
            &.current-time-display,
            &.duration,
            &.picture-in-picture-control,
            &.remaining-time,
            &.volume-control,
            &.play-progress::before {
                display: none;
            }
        }
    
        .mute-control {
            transition: none;
    
            &.hover,
            &.focus,
            &.active {
                transition: none;
            }
    
            &.span {
                transition: none;
    
                &.hover,
                &.focus,
                &.active {
                    transition: none;
                }
            }
        }
    
        .video-button {
            background-color: transparent;
            color: var(--black);
        }
    
        .video-progress-holder {
            margin: 0 0 0 10px;
        }
    
        .volume-panel-hover {
            pointer-events: none;
    
            &.horizontal:active,
            &.horizontal.volume-panel-hover {
                width: unset;
                transition: none;
                display: none;
            }
        }
    
        .volume-control,
        .volume-panel-horizontal {
            display: none;
        }
    
        .video-slider {
            top: 15px;
        }
    
        .progress-control {
            position: relative;
            bottom: 2px;
        }
    
        .mute-control-button {
            position: absolute;
            left: 400px;
            bottom: 15px;
        }
    
        .play-control-button {
            position: absolute;
            right: 55px;
            bottom: 15px;
        }
    }

}
