// Format impulse
.advertisement {
    &-container {
        .product_pub-info {
            z-index: 2;
        }
    }
    
    &-content.impulse {
        position: relative;
        width: 1024px;
        height: 120px;
        left: 16px;
        padding: 0;
        background: var(--light-grey);
        margin: 0 auto;

        .button-close {
            right: 0;
            left: auto;
        }

        .top-flags {
            left: 0;
        }

        .advertisement-product {
            width: 480px;
            padding: 0;
        }

        .advertisement-media {
            height:120px;

            .video-js {
                max-height: 120px;
            }
        }

        .product_pub-image {
            .image {
                height: 40px;
            }
        }

        .splide__arrows {
            left: 0;
            right: 0;
        }

        .splide__pagination {
            left: initial;
            transform: translateX(50%);
        }

        @media (min-width: 1280px) {
            width: 1280px;
            left: 16px;

            .advertisement-media {
                width: 800px;
            }

            .video_pub-container.with-background-format {
                aspect-ratio: 16 / 9;
            }
        }

        @media (min-width: 1600px) {
            width: 1600px;
            left: 8px;

            .advertisement-media {
                width: 1120px;
            }
            
            .video_pub-container.with-background-format {
                aspect-ratio: 16 / 9;
            }
        }
        
        .overlay {
            z-index: 10;
        }
    }

    &-content.impulse_display {
        padding: 0;
        
        .button-close {
            right: 0;
        }

        .top-flags {
            transform: translateX(15px);
        }

        .advertisement-media-full {
            width: 100vw;
            height: 120px;

            .image_pub-container {
                text-align: center;
            }

            .video_pub-image {
                position: absolute;
                height: 100%;
                width: auto;
                left: 50%;
                transform: translate(-50%);
            }
        }

        .advertisement-media {
            height: 120px;
            background: var(--light-grey);
            width: 544px;
    
            @media (min-width: 1280px) {            
                width: 800px;
            }

            @media (min-width: 1600px) {
                width: 1120px;
            }

            .video-js {
                height: 120px;
            }
    
            
            .video_pub-image {
                height: auto;
            }

            .video_pub-link {
                width: 100%;
                height: 100%;
                object-fit: cover;
                justify-content: center;
                align-items: center;
                display: flex;
                overflow: hidden;
            }
        }
    }

    &-content.impulse_product {
        width: 1024px;
        margin: 0 auto;
        padding: 0;
        left: 8px;

        @media (min-width: 1280px) {
            width: 1280px;
        }

        @media (min-width: 1600px) {
            width: 1600px;
        }

        .top-flags {
            left: 0;
        }

        .button-close {
            right: 0;
            left: initial;
        }

        .advertisement-media {
            width: 544px;
            height: 120px;

            @media (min-width: 1280px) {
                width: 800px;
            }

            @media (min-width: 1600px) {
                width: 1120px;
            }

            .video_pub-image {
                left: 50%;
                transform: translateX(-50%);
                position: relative;
                overflow: hidden;
            }
        }

        .video_pub-container {
            overflow: hidden;
        }

        .advertisement-product {
            width: 480px;
            flex: 0;
        }

        .product-slider .splide__arrows {
            padding: 0;
            left: -20px;
            right: -20px;
        }
        
        .product_pub-image {
            .image {
                height: 40px;
            }
        }

        .product_pub-card {
            max-height: 110px;
            overflow: hidden;
        }

        .splide__pagination {
            width: 100%;
            max-width: 100%;
            left: 0;
        }
    }

    &-container[data-position="advertisement-bottom"] {
        position: fixed;
        z-index: 10;
        height: 120px;
        max-height: 120px;
        bottom: 0;
        left: 0;
        width: 100vw;
        margin-left: -16px;
        transition: max-height 0.3s ease-out;

        .video_pub-container.with-background-format {
            width: 100%;
            aspect-ratio: 16 / 9;
        }

        .top-flags {
            top: -27px;
            left: 0;
            transition: top 0.3s ease-out;
            padding-left: 15px;
            box-shadow: -5px -1px 18px -5px #c4c4c4;
        }

        &.js-closed {
            max-height: 0;
            transition: max-height 0.3s ease-out;

            .top-flags {
                top: 0;
                transition: top 0.3s ease-out;
            }
        }

        &::before {
            content: '';
            position: absolute;
            background: var(--light-grey);
            width: 200vw;
            height: 100%;
            box-shadow: -5px -1px 18px -5px #c4c4c4;
        }
        
        .button-close {
            position: absolute;
            top: -24px;
            width: 24px;
            height: 24px;
            padding: 0;
            border: 0;
            background: var(--white);
            cursor: pointer;
            box-shadow: -5px -1px 18px -5px #c4c4c4;
            
            &::after {
                display: block;
                content: url(../../../../version_common/images/pictos/arrow-blue-down.svg);
                width: 24px;
                height: 24px;
                background: url(../../../../version_common/images/pictos/arrow-blue-down.svg);
                background-size: 24px;
                transform: rotate(0deg);
                transition: transform 0.3s linear;

                .js-closed&{
                    transform: rotate(180deg);
                    transition: transform 0.3s ease-out;
                }
            }
        }

        .product-slider {
            height: 120px;
            padding: 0;
            background: var(--white)
        }

        .product_pub-bottom {
            height: 120px;
            padding: 8px 0;

            .name {
                width: auto;
                margin-bottom: 0;
                line-height: 14px;
            }

            .product_price-block {
                display: flex;
                flex-direction: row-reverse;
                margin-top: 0;
                align-items: center;
            }

            .price, 
            .price_promo {
                margin-top: 8px;
                font-size: 16px;
                line-height: 16px;
            }

            .product_pub-info {
                justify-content: flex-start;
            }

            .product_pub-cta {
                position: relative;
                margin: 8px 24px;
                bottom: initial;
                right: initial;

                .button-add-basket {
                    width: 32px;
                    height: 32px;
                    padding: 8px;
                }
            }
        }
    }
}

.modal.popin_video .container {
    width: 868px;
    height: 310px;
    padding: 16px 20px 16px 0;
    
    
    @media (min-width: 1280px) {
        width: 960px;
        height: 360px;
    }

    @media (min-width: 1600px) {
        width: 1120px;
        height: 440px;
    }
    
    .main {
        display: flex;
        max-height: initial;
    }

    .video_pub-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 548px;
        height: 310px;

        @media (min-width: 1280px) {
            width: 640px;
            height: 360px;
        }

        @media (min-width: 1600px) {
            width: 784px;
            height: 440px;
        }

        .video-js {
            width: 548px;
            height: 310px;

            @media (min-width: 1280px) {
                width: 640px;
                height: 360px;
            }

            @media (min-width: 1600px) {
                width: 784px;
                height: 440px;
            }
        }
    }
        
    .product-swiper {
        position: relative;
        left: 540px;
        width: 310px;
        height: 440px;
        padding: 0 16px;

        @media (min-width: 1280px) {
            width: 300px;
            left: 640px;
        }

        @media (min-width: 1600px) {
            width: 310px;
            height: 440px;
            left: 784px;
        }

        .product_pub-card {
            padding-bottom: 10px;
            border-bottom: 1px solid var(--low-grey);

            .product_pub-image .image {
                height: auto;
                max-width: 80%;
            }
        }

        .product_pub-cta {
            margin: 0;
        }

        .product_pub-info {
            justify-content: space-between;
            margin: 0;
        }

        .product_pub-bottom .name {
            width: 150px;
        }

        .product_pub-details {
            width: 150px;

            .category {
                width: 150px;
            }
        }
    }

    .advertisement-product {
        width: 336px;
    }
}
