.bloc-reinsurance {
    margin: 20px 0;

    .title {
        font-family: var(--font-bold);
        font-size: 18px;
        margin-bottom: 16px;
    }

    .card-title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        font-family: var(--font-bold);
        font-size: 14px;
        line-height: 20px;
        color: var(--dark-grey);
        margin: 16px auto;
        z-index: 1;

        @media @desktopXL { font-size: 16px; }

        &::before {
            content: "";
            display: flex;
            align-items: center;
            width: 45px;
            height: 40px;
            flex-shrink: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        &.garantie::before {
            background-image: url(../../../version_common/styles/images/pictos/darty_eco.svg);
            height: 47px;
        }

        &.livraison::before {
            background-image: url(../../../version_common/styles/images/pictos/darty_delivery.svg);
        }

        &.retrait::before {
            background-image: url(../../../version_common/styles/images/pictos/click_and_collect.svg);
        }

        &.conseils::before {
            background-image: url(../../../version_common/styles/images/pictos/darty_idees.svg);
        }
    }

    .card-logo {
        max-width: 45px;
        height: auto;
    }

    .logo-personnalise {
        &::before {
            display: none;
        }
    }

    .card {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 19px;
        z-index: 0;

        @media @desktop { padding: 0 20px; }

        @media @desktopXL { padding: 0 60px; }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 152px;
            bottom: 0;
            background-color: var(--white);
            border-radius: 3px;
            z-index: 0;
        }

        .text-title {
            color: var(--dark-grey);
            text-decoration: none;

            &::before {
                content: "";
                position: absolute;
                inset: 0;
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        gap: 8px;

        .img {
            width: 100%;
            height: auto;
            border-radius: 3px;
            z-index: 1;
        }
    }

    &.compact {
        .content {
            gap: 0;
            background-color: var(--white);
            border-radius: 3px;
            padding: 20px 19px;

            @media @desktop { padding: 24px 28px; }

            @media @desktopXL { padding: 18px 32px; }
        }

        .compact_title {
            display: none;
            width: 155px;
            font-family: var(--font-bold);
            font-size: 22px;
            line-height: 28px;
            text-transform: uppercase;
            color: var(--red);

            @media @desktop {
                display: block;
            }
        }

        .card {
            padding: 0;
            position: relative;
            border-right: solid 1px var(--border-grey);

            &:last-of-type {
                border-right: none;
            }

            &::before {
                display: none;
            }
        }

        .card-title {
            flex-flow: row nowrap;
            font-size: 14px;
            line-height: 1.2;
            gap: 5px;
            margin: 0;
            padding: 0 16px;

            @media @desktop {
                font-size: 16px;
                padding: 0 24px;
            }

            @media @desktopXL {
                padding: 0 34px;
            }

            &::before {
                width: 36px;
                height: 38px;
                margin-right: 22px;
            }

            &.garantie::before {
                width: 36.57px;
                height: 38.48px;
            }

            &.livraison::before {
                width: 39.47px;
            }

            .text-title {
                text-align: initial;
            }
        }
    }
}
