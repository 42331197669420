.multi-slider {
    margin: 24px 0;

    .buttons-list {
        display: none;
        flex-flow: row nowrap;

        &.js-active {
            display: flex;
        }

        .button-item {
            font-size: 12px;
            line-height: 16px;
            padding: 7px 12px;
            background-color: var(--white);
            border: solid 1px var(--low-grey);
            border-radius: 16px;
            margin-right: 10px;
            user-select: none;
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            &.js-active {
                color: var(--white);
                background-color: var(--blue);
                border: solid 1px var(--blue);
            }
        }
    }

    .wrapper {
        position: relative;

        .bloc-products {
            display: none;

            &.js-active {
                display: block;
            }
        }
    }
}
