.advertisement-container {
    &.totem {
        height: 530px;
        aspect-ratio: inherit;
        overflow-x: clip;

        .product_pub-info {
            z-index: 1;
        }

        .advertisement-content {
            flex-direction: column;
            padding: 0;

            .advertisement-media {
                height: 530px;
                background: var(--light-grey);
                width: 100%;

                .video-js {
                    height: 100%;
                    width: 100%;
                }

                .video_pub-image {
                    height: 100%;
                }

                .video_pub-container {
                    height: 100%;

                    .vjs-control-bar {
                        top: 30px;
                        height: auto;
                    }
                }
            }

            .advertisement-product {
                width: 85%;
                padding: 0;
                position: absolute;
                bottom: 16px;
                height: 137px;
                right: -100%;
                transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out;
                opacity: 0;

                .splide {
                    .image {
                        height: 60px;
                    }

                    .splide__pagination {
                        display: none;
                    }

                    .splide__slide {
                        border-radius: 4px;
                    }
                }
            }

            .product_pub-image {
                padding: 25px 5px 0;
                align-self: flex-start;
                width: 30%;
            }

            .product_pub-bottom {
                width: 70%;
                height: 135px;
                padding: 8px 0;

                .product_price-block {
                    .price {
                        font-size: 16px;
                    }

                    .striped_price {
                        font-size: 16px;
                    }
                }
            }

            .product_pub-details {
                .category,
                .name {
                    width: inherit;
                }
            }
        }

        &:hover .advertisement-product {
            right: 20px;
            opacity: 1;
        }
    }
}
