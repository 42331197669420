[data-tooltip],
.tooltip-wrap {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: auto;

    &.bottom {
        &::after {
            top: 22px;
        }

        .tooltip,
        &::before {
            top: 33px;
            bottom: unset;
        }
    }

    &.left {
        .tooltip,
        &::before {
            transform: translateX(-85%);
        }
    }

    &::after {
        display: none;
        content: "";
        position: absolute;
        left: 50%;
        top: -26px;
        bottom: -6px;
        transform: translateX(-50%) rotate(45deg);
        height: 12px;
        width: 12px;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjEwIDEwIDQwIDQwIj4KICA8cGF0aCBkPSJNMTAgMTAgSCA5MCBWIDkwIEggMTAgWiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4=) no-repeat center;
        background-size: 100%;
    }

    .tooltip .link {
        color: inherit;
    }

    .tooltip,
    &::before {
        display: none;
        content: attr(data-tooltip);
        position: absolute;
        bottom: 30px;
        right: 0;
        width: 250px;
        text-align: left;
        padding: 13px 16px;
        border-radius: 4px;
        background-color: var(--white);
        color: var(--black);
        font-size: 14px;
        line-height: 18px;
        box-shadow: 0 2px 6px 1px rgba(69, 69, 69, 0.16);
    }

    &:hover, &:active {
        .tooltip,
        &::before,
        &::after {
            display: block;
            z-index: 10;
        }
    }

    .bottom {
        &::before,
        &::after {
            top: 100%;
            bottom: auto;
            margin-top: 10px;
        }

        &::after {
            transform: translateX(-50%) rotate(-135deg);
        }
    }
}
