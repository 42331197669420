.bloc-editorial {
    margin: 20px 0;

    .content {
        font-size: 14px;
        line-height: 18.2px;

        /* les elements suivants proviennent directement du BO */

        /* stylelint-disable-next-line */
        h1, h2, h3, h4, h5, h6 {
            font-family: var(--font-bold);
            margin-bottom: 10px;
        }

        /* stylelint-disable-next-line */
        p { margin-bottom: 10px; }

        /* stylelint-disable-next-line */
        strong, b {
            font-family: var(--font-bold);
        }

        /* stylelint-disable-next-line */
        em, i { font-style: italic; }

        /* stylelint-disable-next-line */
        a { color: inherit; }

        /* stylelint-disable-next-line */
        ul, ol { margin-left: 16px; }

        /* stylelint-disable-next-line */
        ul { list-style-type: disc; }

        /* stylelint-disable-next-line */
        ol { list-style-type: decimal; }
    }

    .title {
        display: block;
        margin-bottom: 16px;
        font-family: var(--font-bold-alt);
        font-size: 18px;
    }

    .more {
        font-family: var(--font-bold);
        font-size: 18px;
    }

    &--is-collapsible {
        .content {
            display: none;
        }

        .more {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;
            border: none;
            background-color: transparent;
            padding-left: 0;
            font-family: var(--font-regular);
            color: var(--dark-grey);

            &::after {
                content: url(../../../version_common/images/pictos/arrow-blue-down.svg);
                width: 40px;
                transition: transform .3s linear;
            }
        }

        &.js-expand {
            .more::after {
                transform: rotate(180deg);
            }

            .content {
                display: block;
            }
        }
    }
}
