@font-face {
  font-family: "Open Sans";
  src: url(../../../version_common/styles/fonts/opensans-regular-webfont.eot);
  src: url(../../../version_common/styles/fonts/opensans-regular-webfont.eot?#iefix) format("embedded-opentype"), url(../../../version_common/styles/fonts/opensans-regular-webfont.woff2) format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Bold";
  src: url(../../../version_common/styles/fonts/opensans-bold-webfont.eot);
  src: url(../../../version_common/styles/fonts/opensans-bold-webfont.eot?#iefix) format("embedded-opentype"), url(../../../version_common/styles/fonts/opensans-bold-webfont.woff2) format("woff2");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: Manrope;
  src: url(../../../version_common/styles/fonts/Manrope-Medium.eot);
  src: url(../../../version_common/styles/fonts/Manrope-Medium.eot?#iefix) format("embedded-opentype"), url(../../../version_common/styles/fonts/Manrope-Medium.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Manrope Bold";
  src: url(../../../version_common/styles/fonts/Manrope-Bold.eot);
  src: url(../../../version_common/styles/fonts/Manrope-Bold.eot?#iefix) format("embedded-opentype"), url(../../../version_common/styles/fonts/Manrope-Bold.woff2) format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Party Bold";
  src: url(../../../version_common/styles/fonts/Party-Bold.eot);
  src: url(../../../version_common/styles/fonts/Party-Bold.eot?#iefix) format("embedded-opentype"), url(../../../version_common/styles/fonts/Party-Bold.woff2) format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
:root {
  --red: #e21212;
  --red-hover: #d01111;
  --red-pressed: #bd0f0f;
  --blue: #006af5;
  --blue-hover: #0061e0;
  --blue-pressed: #0058cc;
  --marketplace: #008391;
  --marketplace-hover: #007682;
  --marketplace-pressed: #006974;
  --light-blue: #ccdff9;
  --light-blue-hover: #ccdff9;
  --light-blue-pressed: #b2cdf0;
  --purple: #662d91;
  --orange: #fe741b;
  --green: #007a38;
  --yellow: #ffdb00;
  --error: var(--red);
  --success: var(--green);
  --message: #fff199;
  --disabled: #d8d8d8;
  --text-disabled: #656565;
  --black: #161616;
  --black-hover: #292929;
  --black-pressed: #333;
  --white: #fff;
  --white-hover: #e5e5e5;
  --white-pressed: #ccc;
  --dark-grey: #333;
  --grey: #454545;
  --mid-grey: #757575;
  --soft-grey: #989898;
  --low-grey: #d8d8d8;
  --light-grey: #f5f5f5;
  --border-grey: #ececec;
  --border-color: var(--low-grey);
  --back-office: #c8ff00;
  --back-office-submit: #647f00;
  --overlay: rgba(0, 0, 0, 0.6);
  --overlay-light: rgba(0, 0, 0, 0.2);
  --shadow: 0 0 10px 0 rgba(69, 69, 69, 0.08);
  --shadow-bottom: 0 -5px 10px 0 rgba(69, 69, 69, 0.08);
  --shadow-sticky: 0 6px 10px 3px rgba(69, 69, 69, 0.16), 0 0 2px 0 rgba(69, 69, 69, 0.14);
  --shadow-conversational: 0 2px 4px 0 rgba(69, 69, 69, 0.08), 0 0 2px 0 rgba(69, 69, 69, 0.14);
}
.bloc-editorial {
  margin: 20px 0;
}
.bloc-editorial .content {
  font-size: 14px;
  line-height: 18.2px;
  /* les elements suivants proviennent directement du BO */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
  /* stylelint-disable-next-line */
}
.bloc-editorial .content h1,
.bloc-editorial .content h2,
.bloc-editorial .content h3,
.bloc-editorial .content h4,
.bloc-editorial .content h5,
.bloc-editorial .content h6 {
  font-family: var(--font-bold);
  margin-bottom: 10px;
}
.bloc-editorial .content p {
  margin-bottom: 10px;
}
.bloc-editorial .content strong,
.bloc-editorial .content b {
  font-family: var(--font-bold);
}
.bloc-editorial .content em,
.bloc-editorial .content i {
  font-style: italic;
}
.bloc-editorial .content a {
  color: inherit;
}
.bloc-editorial .content ul,
.bloc-editorial .content ol {
  margin-left: 16px;
}
.bloc-editorial .content ul {
  list-style-type: disc;
}
.bloc-editorial .content ol {
  list-style-type: decimal;
}
.bloc-editorial .title {
  display: block;
  margin-bottom: 16px;
  font-family: var(--font-bold-alt);
  font-size: 18px;
}
.bloc-editorial .more {
  font-family: var(--font-bold);
  font-size: 18px;
}
.bloc-editorial--is-collapsible .content {
  display: none;
}
.bloc-editorial--is-collapsible .more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding-left: 0;
  font-family: var(--font-regular);
  color: var(--dark-grey);
}
.bloc-editorial--is-collapsible .more::after {
  content: url(../../../version_common/images/pictos/arrow-blue-down.svg);
  width: 40px;
  transition: transform 0.3s linear;
}
.bloc-editorial--is-collapsible.js-expand .more::after {
  transform: rotate(180deg);
}
.bloc-editorial--is-collapsible.js-expand .content {
  display: block;
}
.bloc-reinsurance {
  margin: 20px 0;
}
.bloc-reinsurance .title {
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 16px;
}
.bloc-reinsurance .card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-grey);
  margin: 16px auto;
  z-index: 1;
}
@media only screen and (min-width: 1600px) {
  .bloc-reinsurance .card-title {
    font-size: 16px;
  }
}
.bloc-reinsurance .card-title::before {
  content: "";
  display: flex;
  align-items: center;
  width: 45px;
  height: 40px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.bloc-reinsurance .card-title.garantie::before {
  background-image: url(../../../version_common/styles/images/pictos/darty_eco.svg);
  height: 47px;
}
.bloc-reinsurance .card-title.livraison::before {
  background-image: url(../../../version_common/styles/images/pictos/darty_delivery.svg);
}
.bloc-reinsurance .card-title.retrait::before {
  background-image: url(../../../version_common/styles/images/pictos/click_and_collect.svg);
}
.bloc-reinsurance .card-title.conseils::before {
  background-image: url(../../../version_common/styles/images/pictos/darty_idees.svg);
}
.bloc-reinsurance .card-logo {
  max-width: 45px;
  height: auto;
}
.bloc-reinsurance .logo-personnalise::before {
  display: none;
}
.bloc-reinsurance .card {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 19px;
  z-index: 0;
}
@media only screen and (min-width: 1280px) {
  .bloc-reinsurance .card {
    padding: 0 20px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-reinsurance .card {
    padding: 0 60px;
  }
}
.bloc-reinsurance .card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 152px;
  bottom: 0;
  background-color: var(--white);
  border-radius: 3px;
  z-index: 0;
}
.bloc-reinsurance .card .text-title {
  color: var(--dark-grey);
  text-decoration: none;
}
.bloc-reinsurance .card .text-title::before {
  content: "";
  position: absolute;
  inset: 0;
}
.bloc-reinsurance .content {
  display: flex;
  align-items: center;
  gap: 8px;
}
.bloc-reinsurance .content .img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  z-index: 1;
}
.bloc-reinsurance.compact .content {
  gap: 0;
  background-color: var(--white);
  border-radius: 3px;
  padding: 20px 19px;
}
@media only screen and (min-width: 1280px) {
  .bloc-reinsurance.compact .content {
    padding: 24px 28px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-reinsurance.compact .content {
    padding: 18px 32px;
  }
}
.bloc-reinsurance.compact .compact_title {
  display: none;
  width: 155px;
  font-family: var(--font-bold);
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  color: var(--red);
}
@media only screen and (min-width: 1280px) {
  .bloc-reinsurance.compact .compact_title {
    display: block;
  }
}
.bloc-reinsurance.compact .card {
  padding: 0;
  position: relative;
  border-right: solid 1px var(--border-grey);
}
.bloc-reinsurance.compact .card:last-of-type {
  border-right: none;
}
.bloc-reinsurance.compact .card::before {
  display: none;
}
.bloc-reinsurance.compact .card-title {
  flex-flow: row nowrap;
  font-size: 14px;
  line-height: 1.2;
  gap: 5px;
  margin: 0;
  padding: 0 16px;
}
@media only screen and (min-width: 1280px) {
  .bloc-reinsurance.compact .card-title {
    font-size: 16px;
    padding: 0 24px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-reinsurance.compact .card-title {
    padding: 0 34px;
  }
}
.bloc-reinsurance.compact .card-title::before {
  width: 36px;
  height: 38px;
  margin-right: 22px;
}
.bloc-reinsurance.compact .card-title.garantie::before {
  width: 36.57px;
  height: 38.48px;
}
.bloc-reinsurance.compact .card-title.livraison::before {
  width: 39.47px;
}
.bloc-reinsurance.compact .card-title .text-title {
  text-align: initial;
}
.bloc-banner {
  margin: 24px 0;
  text-align: center;
}
.bloc-banner .link {
  display: block;
}
.bloc-banner .image {
  display: block;
  width: 100%;
  height: auto;
}
.bloc-mosaic {
  margin: 20px auto;
}
.bloc-mosaic .wrapper {
  height: 450px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 450px;
  gap: 16px;
}
.bloc-mosaic .item {
  position: relative;
  width: 488px;
  padding: 16px;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .item {
    width: 616px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic .item {
    width: 776px;
  }
}
.bloc-mosaic .main {
  display: flex;
  flex-flow: column;
  flex: 1;
}
.bloc-mosaic .picture {
  display: block;
  margin: auto;
  position: relative;
}
.bloc-mosaic .picture-link {
  width: 100%;
}
.bloc-mosaic .arrow {
  position: absolute;
  bottom: 16px;
  right: 16px;
  pointer-events: none;
}
.bloc-mosaic .details {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: auto;
  background-color: var(--white);
  border-radius: 3px;
  padding: 16px;
  z-index: 2;
}
.bloc-mosaic .details .arrow {
  bottom: 32px;
  right: 32px;
}
.bloc-mosaic .title,
.bloc-mosaic .category,
.bloc-mosaic .name,
.bloc-mosaic .hook,
.bloc-mosaic .mention,
.bloc-mosaic .commercial,
.bloc-mosaic .service .label {
  line-height: 1.3;
}
.bloc-mosaic .exclusive,
.bloc-mosaic .service,
.bloc-mosaic .mention {
  margin-top: 4px;
  line-height: 28px;
}
.bloc-mosaic .title {
  font-family: var(--font-bold);
  font-size: 20px;
  text-decoration: none;
  color: var(--dark-grey);
}
.bloc-mosaic .title::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 1;
}
.bloc-mosaic .category {
  font-size: 16px;
}
.bloc-mosaic .name {
  font-family: var(--font-bold);
  font-size: 16px;
}
.bloc-mosaic .hook {
  font-size: 14px;
}
.bloc-mosaic .sub {
  margin-top: auto;
}
.bloc-mosaic .price {
  font-family: var(--font-bold);
  font-size: 28px;
  margin-bottom: 4px;
}
.bloc-mosaic .price.promo {
  color: var(--red);
}
.bloc-mosaic .price.striped {
  font-size: 14px;
  color: var(--mid-grey);
  text-decoration: line-through;
  margin: 4px 0;
  font-family: var(--font-regular);
}
.bloc-mosaic .mention {
  font-size: 16px;
  color: var(--black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 22px;
}
.bloc-mosaic .commercial {
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--red);
  margin: auto 0;
}
.bloc-mosaic .exclusive {
  font-size: 16px;
}
.bloc-mosaic .exclusive .darty {
  font-family: var(--font-bold);
  color: var(--red);
}
.bloc-mosaic .service {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: 22px;
  font-size: 16px;
  color: var(--black);
}
.bloc-mosaic .service::before {
  content: "";
  display: block;
  flex: none;
  height: 28px;
  width: 28px;
  margin-right: 12px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.bloc-mosaic .service .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bloc-mosaic .service.conseils::before {
  background-image: url(../../../version_common/styles/images/pictos/conseil.svg);
}
.bloc-mosaic .service.garantie::before {
  background-image: url(../../../version_common/styles/images/pictos/darty_umbrella.svg);
}
.bloc-mosaic .service.livraison::before {
  background-image: url(../../../version_common/styles/images/pictos/darty_delivery.svg);
}
.bloc-mosaic .service.retrait::before {
  background-image: url(../../../version_common/styles/images/pictos/click_and_collect.svg);
}
.bloc-mosaic .large {
  display: flex;
  flex-flow: column;
}
.bloc-mosaic .large .details {
  padding: 24px 16px 16px;
}
.bloc-mosaic .large .title,
.bloc-mosaic .large .category,
.bloc-mosaic .large .name,
.bloc-mosaic .large .hook,
.bloc-mosaic .large .commercial {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bloc-mosaic .large .commercial {
  font-size: 28px;
  padding-top: 14px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .large .commercial {
    padding-top: 0;
  }
}
.bloc-mosaic .large .title,
.bloc-mosaic .large .name,
.bloc-mosaic .large .category {
  margin-bottom: 4px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .large .title,
  .bloc-mosaic .large .name,
  .bloc-mosaic .large .category {
    margin-bottom: 8px;
  }
}
.bloc-mosaic .large .title {
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 14px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .large .title {
    margin-bottom: 24px;
  }
}
.bloc-mosaic .large .hook {
  margin-top: 8px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .large .hook {
    margin-top: 12px;
  }
}
.bloc-mosaic .large .price_wrapper {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic .large .price_wrapper {
    margin: auto 0;
    flex-direction: row;
    align-items: center;
  }
  .bloc-mosaic .large .price_wrapper .striped-prices {
    margin-left: 10px;
  }
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .large {
    flex-flow: row nowrap;
  }
  .bloc-mosaic .large .details {
    height: 100%;
    width: 50%;
  }
}
.bloc-mosaic .small {
  display: flex;
  flex-flow: row nowrap;
}
.bloc-mosaic .small .title,
.bloc-mosaic .small .name,
.bloc-mosaic .small .category {
  margin-bottom: 4px;
}
.bloc-mosaic .small .details {
  height: 100%;
  width: 50%;
}
.bloc-mosaic .small .title,
.bloc-mosaic .small .category,
.bloc-mosaic .small .name,
.bloc-mosaic .small .hook,
.bloc-mosaic .small .commercial {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.bloc-mosaic .small .category {
  display: none;
}
.bloc-mosaic .small .price_wrapper {
  margin-top: auto;
  display: flex;
  align-items: center;
}
.bloc-mosaic .small .price_wrapper .striped-prices {
  margin-left: 10px;
}
.bloc-mosaic .flex {
  display: flex;
  flex-flow: column;
  gap: 16px;
}
.bloc-mosaic .flex .item {
  height: 50%;
  flex-flow: row nowrap;
}
.bloc-mosaic .lifestyle {
  padding: 0;
}
.bloc-mosaic .lifestyle .picture {
  height: 100%;
  width: auto;
  object-fit: cover;
  object-position: left center;
}
.bloc-mosaic .product.large .picture,
.bloc-mosaic .marketing.large .picture {
  width: auto;
  height: auto;
  overflow: auto;
  margin: auto;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .product.large .picture,
  .bloc-mosaic .marketing.large .picture {
    height: auto;
    width: 292px;
    padding-right: 16px;
    margin-bottom: auto;
  }
}
.bloc-mosaic .product.large .details,
.bloc-mosaic .marketing.large .details {
  flex: 0;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .product.large .details,
  .bloc-mosaic .marketing.large .details {
    flex: unset;
  }
}
.bloc-mosaic .product .image_halo,
.bloc-mosaic .marketing .image_halo {
  margin: auto auto 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 0;
  min-width: 0;
  height: 100%;
  width: auto;
}
.bloc-mosaic .product .image_halo .picture,
.bloc-mosaic .marketing .image_halo .picture {
  height: 100%;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic .product .image_halo .picture,
  .bloc-mosaic .marketing .image_halo .picture {
    height: auto;
  }
}
.bloc-mosaic .product .image_halo::before,
.bloc-mosaic .marketing .image_halo::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: auto;
  box-shadow: 0 0 110px 55px var(--white);
  width: 0;
  height: 0;
  border-radius: 100px;
  z-index: 0;
}
.bloc-mosaic .product.lifestyle,
.bloc-mosaic .marketing.lifestyle {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  padding: 16px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left;
}
.bloc-mosaic .product.lifestyle .details,
.bloc-mosaic .marketing.lifestyle .details {
  position: static;
  margin-top: 0;
  height: auto;
}
.bloc-mosaic .product.lifestyle .picture,
.bloc-mosaic .marketing.lifestyle .picture {
  height: 100%;
  width: auto;
  margin: 0 auto 0 0;
}
.bloc-mosaic .product.small .picture,
.bloc-mosaic .marketing.small .picture {
  width: auto;
}
.bloc-mosaic .product.small .image_halo,
.bloc-mosaic .marketing.small .image_halo {
  max-width: 50%;
}
.bloc-mosaic .product.small .image_halo .picture,
.bloc-mosaic .marketing.small .image_halo .picture {
  height: 100%;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item {
  padding: 0;
  /* stylelint-disable at-rule-empty-line-before */
  /* stylelint-enable at-rule-empty-line-before */
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .image_halo {
  margin: auto;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .image_halo .picture {
  object-fit: contain;
  padding: 0;
  width: 100%;
  height: 100%;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .commercial {
  margin: 0 0 auto;
  font-family: var(--font-bold);
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .details {
  margin: 16px 16px 16px 0;
  height: auto;
  flex: 0 0 auto;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .sub .service,
.bloc-mosaic.bloc-mosaic--is-restyled .item .sub .mention {
  margin-top: 7px;
  padding-right: 45px;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .service,
.bloc-mosaic.bloc-mosaic--is-restyled .item .mention {
  font-size: 13px;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item:not(.small) .sub {
  height: 40px;
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.lifestyle {
    background-position: -40px center;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.image .picture-link--with-background-image {
  background-position: center;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .category {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  padding-bottom: 2px;
  text-transform: uppercase;
  font-size: inherit;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .text-item {
  margin: 0;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .details {
  padding: 20px;
  width: 274px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .details {
    width: 350px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .details {
    width: 372px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small.lifestyle {
  background-size: auto;
  background-position: -60px center;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small.lifestyle {
    background-position: -40px center;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .text-item + .text-item {
  margin: 5px 0 0;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .category {
  padding-bottom: 3px;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .name {
  font-size: 13px;
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .name {
    font-size: 14px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .title {
  font-size: 21px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .title {
    font-size: 23px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .title {
    font-size: 25px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .price {
  font-size: 27px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .price {
    font-size: 35px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .price.striped {
  font-size: 17px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .price.striped {
    font-size: 22px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .commercial {
  font-size: 24px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small .commercial {
    font-size: 27px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large {
  flex-flow: row nowrap;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .text-item + .text-item {
  margin: 15px 0 0;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .details {
  padding: 25px;
  width: 254px;
  flex: 0 0 auto;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .details {
    width: 292px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .details {
    width: 372px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large.lifestyle {
  background-size: auto;
  background-position: -80px center;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large.lifestyle .details {
  flex: unset;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large.lifestyle {
    background-position: -30px center;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .name {
  font-size: 16px;
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .name {
    font-size: 18px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .title {
  font-size: 23px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .title {
    font-size: 27px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .title {
    font-size: 30px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .price {
  font-size: 35px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .price {
    font-size: 42px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .price.striped {
  font-size: 22px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .price.striped {
    font-size: 26px;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .commercial {
  font-size: 28px;
}
@media only screen and (min-width: 1280px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .commercial {
    font-size: 33px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large .commercial {
    font-size: 35px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-mosaic.bloc-mosaic--is-restyled .item.large.lifestyle,
  .bloc-mosaic.bloc-mosaic--is-restyled .item.small.lifestyle {
    background-position: 0 center;
  }
}
.bloc-mosaic.bloc-mosaic--is-restyled .item .chevron-cta {
  position: absolute;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.small .chevron-cta {
  right: 36px;
  bottom: 36px;
}
.bloc-mosaic.bloc-mosaic--is-restyled .item.large .chevron-cta {
  right: 41px;
  bottom: 41px;
}
.bloc-rich-banner {
  margin: 24px 0;
  position: relative;
}
.bloc-rich-banner .wrapper {
  display: flex;
  padding: 16px;
  align-items: center;
}
.bloc-rich-banner .link {
  text-decoration: none;
  color: var(--dark-grey);
}
.bloc-rich-banner .link::before {
  content: "";
  position: absolute;
  inset: 0;
}
.bloc-rich-banner .banner-text {
  display: flex;
  height: 100%;
  width: 50%;
  padding-right: 16px;
  justify-content: center;
  flex-direction: column;
  font-family: var(--font-bold);
}
.bloc-rich-banner .banner-text .title {
  font-size: 28px;
  margin-bottom: 1rem;
}
@media only screen and (min-width: 1280px) {
  .bloc-rich-banner .banner-text .title {
    font-size: 32px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-rich-banner .banner-text .title {
    font-size: 36px;
  }
}
.bloc-rich-banner .banner-text .text {
  line-height: 28px;
  font-size: 16px;
}
@media only screen and (min-width: 1280px) {
  .bloc-rich-banner .banner-text .text {
    font-size: 22px;
  }
}
.bloc-rich-banner .banner-image {
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}
.bloc-rich-banner .banner-image-container {
  overflow: hidden;
  height: 267px;
  width: 50%;
}
@media only screen and (min-width: 1280px) {
  .bloc-rich-banner .banner-image-container {
    height: 357px;
  }
}
.bloc-custom-banner {
  margin: 24px 0;
}
.bloc-custom-banner .wrapper {
  display: flex;
  position: relative;
  height: 230px;
  padding: 16px;
}
.bloc-custom-banner .wrapper.is-link {
  text-decoration: none;
}
.bloc-custom-banner .wrapper.is-with-card {
  padding: 0;
  display: block;
}
.bloc-custom-banner .image {
  height: 100%;
  width: 100%;
  margin: auto;
  object-fit: contain;
}
.bloc-custom-banner .image.lifestyle {
  width: 100%;
}
.bloc-custom-banner .card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  margin: 16px 16px 16px 0;
  width: 100%;
  min-width: 310px;
  max-width: 496px;
  background-color: var(--white);
  border-radius: 3px;
}
@media only screen and (min-width: 1280px) {
  .bloc-custom-banner .card {
    max-width: 624px;
  }
}
@media only screen and (min-width: 1600px) {
  .bloc-custom-banner .card {
    max-width: 624px;
  }
}
.bloc-custom-banner .cta {
  background-color: var(--red);
  color: var(--white);
  border-radius: 3px;
  padding: 11px 18px;
  min-width: 208px;
  text-align: center;
  margin: auto 0 0 auto;
  text-decoration: none;
}
.bloc-custom-banner .link {
  width: 100%;
}
.bloc-custom-banner .cta::before,
.bloc-custom-banner .link::before {
  content: "";
  position: absolute;
  z-index: 2;
  inset: 0;
}
.bloc-custom-banner .wrapper--is-image-banner .cta {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 1;
}
.bloc-custom-banner .arrow {
  width: auto;
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.bloc-custom-banner .image-wrapper {
  min-height: 213px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.bloc-custom-banner .image-wrapper .image {
  position: relative;
}
.bloc-custom-banner .image-wrapper::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  aspect-ratio: 1;
  height: 0;
  width: 0;
  box-shadow: 0 0 90px 50px var(--white);
  border-radius: 50%;
}
.bloc-custom-banner.is-lifestyle .wrapper {
  padding: 0;
}
.bloc-custom-banner.is-lifestyle .image {
  object-fit: cover;
}
.bloc-custom-banner.is-lifestyle .is-with-card .image {
  object-fit: contain;
  object-position: left;
}
.bloc-custom-banner.is-lifestyle .image-wrapper::before {
  display: none;
}
.bloc-custom-banner .details__header {
  font-family: var(--font-bold);
  color: var(--red);
  font-size: 12px;
}
.bloc-custom-banner .details__content {
  color: var(--dark-grey);
  font-size: 12px;
  line-height: 15px;
}
.bloc-custom-banner .details__content--is-bold {
  font-family: var(--font-bold);
}
.bloc-custom-banner .product {
  color: var(--dark-grey);
}
.bloc-custom-banner .product__family {
  color: var(--dark-grey);
  font-size: 14px;
}
.bloc-custom-banner .product__reference {
  font-family: var(--font-bold);
  color: var(--grey);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
}
.bloc-custom-banner .product__description {
  font-size: 14px;
  line-height: 18px;
}
.bloc-custom-banner .product__discount-offer {
  font-family: var(--font-bold);
  color: var(--red);
  font-size: 24px;
}
.bloc-custom-banner .banner-with-card {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
}
.bloc-custom-banner .legal-mentions {
  color: var(--mid-grey);
  font-size: 8px;
  position: absolute;
  bottom: 5px;
  right: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
}
.header__wrapper {
  margin-bottom: 8px;
}
.header__image {
  width: auto;
  max-height: 40px;
}
.header__link {
  text-decoration: none;
}
.header__link::before {
  content: "";
  position: absolute;
  inset: 0;
}
.header__wrapper--with-picto {
  display: flex;
  align-items: center;
}
.header__wrapper--with-picto .header__image {
  margin-right: 10px;
  height: auto;
  width: auto;
  max-height: 40px;
  max-width: 60px;
}
.header__date-mention {
  color: var(--dark-grey);
  font-size: 12px;
  text-decoration: underline;
}
.header__title {
  font-family: var(--font-bold);
  font-size: 24px;
  line-height: 31px;
  color: var(--grey);
}
.header__subTitle {
  font-family: var(--font-bold);
  font-size: 12px;
  color: var(--dark-grey);
}
.header .price {
  font-family: var(--font-bold);
  font-size: 24px;
  margin-right: 16px;
}
.header .price_wrapper {
  display: flex;
  align-items: flex-end;
}
.header .price.promo {
  color: var(--red);
}
.header .price.striped {
  color: var(--mid-grey);
  font-size: 18px;
  text-decoration: line-through;
}
.splide__slide .header__image {
  width: auto;
}
.center-info {
  display: flex;
  flex-direction: column;
}
.center-info--is-reverted {
  flex-direction: column-reverse;
}
.center-info .discount-price {
  color: var(--red);
  font-size: 20px;
  font-family: var(--font-bold);
  line-height: 27px;
}
.center-info .second-text {
  color: var(--dark-grey);
  font-size: 16px;
  line-height: 20px;
}
.service__text {
  color: var(--dark-grey);
  font-size: 12px;
}
.multi_banner .bloc-custom-banner {
  text-align: left;
}
.multi_banner .bloc-custom-banner .legal-mentions {
  left: 16px;
  right: auto;
}
.multi_banner .bloc-custom-banner .card {
  min-width: 279px;
  max-width: 279px;
}
@media only screen and (min-width: 1280px) {
  .multi_banner .bloc-custom-banner .card {
    max-width: 310px;
  }
}
@media only screen and (min-width: 1600px) {
  .multi_banner .bloc-custom-banner .card {
    max-width: 390px;
  }
}
.multi_banner .bloc-custom-banner .image-wrapper .image {
  height: 100%;
  width: 100%;
}
.multi_banner .bloc-custom-banner .wrapper {
  max-height: 228px;
}
.multi_banner .bloc-custom-banner .wrapper .image {
  height: 100%;
  width: 100%;
  margin: auto;
}
.multi-slider {
  margin: 24px 0;
}
.multi-slider .buttons-list {
  display: none;
  flex-flow: row nowrap;
}
.multi-slider .buttons-list.js-active {
  display: flex;
}
.multi-slider .buttons-list .button-item {
  font-size: 12px;
  line-height: 16px;
  padding: 7px 12px;
  background-color: var(--white);
  border: solid 1px var(--low-grey);
  border-radius: 16px;
  margin-right: 10px;
  user-select: none;
  cursor: pointer;
}
.multi-slider .buttons-list .button-item:last-child {
  margin-right: 0;
}
.multi-slider .buttons-list .button-item.js-active {
  color: var(--white);
  background-color: var(--blue);
  border: solid 1px var(--blue);
}
.multi-slider .wrapper {
  position: relative;
}
.multi-slider .wrapper .bloc-products {
  display: none;
}
.multi-slider .wrapper .bloc-products.js-active {
  display: block;
}
.bloc-banner-pub {
  height: auto;
  margin: 24px auto;
  padding: 0;
  display: none;
  text-align: center;
}
.bloc-banner-pub .bloc-adv-title {
  font-size: 9px;
  text-align: center;
  padding: 5px 0;
}
.expand-block__section {
  margin: 20px 0;
}
.expand-block__list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 160px;
  gap: 30px;
  justify-content: space-evenly;
  padding: 10px;
  margin: 0 -10px;
}
@media only screen and (min-width: 1600px) {
  .expand-block__list {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media only screen and (min-width: 1280px) {
  .expand-block__list {
    grid-auto-rows: 230px;
  }
}
.expand-block__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.expand-block__item {
  background: var(--white);
  border-radius: 5px;
  box-shadow: 0 0 10px var(--overlay-light);
  overflow: hidden;
}
.expand-block__item-node {
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}
.expand-block__item-flip {
  transition: 0.6s;
  transform-style: preserve-3d;
}
.expand-block__item-flip:hover {
  transform: translateY(calc(-100%));
}
.expand-block .title {
  color: var(--dark-grey);
  font-size: 16px;
  text-align: center;
  font-family: "Open Sans Bold", Arial, sans-serif;
  text-decoration: none;
}
.expand-block__sublinks {
  position: absolute;
  top: 100%;
  transform: translateY(10px);
  padding: 10px;
  width: 100%;
  left: 0;
  height: 100%;
}
.expand-block__sublinks .title {
  text-align: left;
}
.expand-block__sublink-item .sublink {
  display: block;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px solid var(--light-grey);
  margin-bottom: 5px;
  padding: 5px;
  color: var(--dark-grey);
}
.expand-block__sublink-item:last-child .sublink {
  border-bottom: 1px solid transparent;
}
.expand-block .picture_wrapper {
  height: 100%;
  display: flex;
  margin: 0 auto;
  background-color: var(--light-grey);
  border-radius: 3px;
  overflow: hidden;
  max-height: 112px;
}
@media only screen and (min-width: 1280px) {
  .expand-block .picture_wrapper {
    max-height: 182px;
  }
}
.expand-block .picture_wrapper .picture {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.expand-block .link {
  text-decoration: none;
  margin: 0 0 20px;
}
.expand-block__wrapper--has-title .link {
  margin: 10px 0;
}
.expand-block .btn-expand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding-left: 0;
  font-family: "Open Sans Bold", Arial, sans-serif;
  font-size: 16px;
  color: var(--blue);
  margin: auto;
  line-height: 15px;
}
.expand-block .btn-expand::after {
  content: url(../../../version_common/images/pictos/arrow-blue-down.svg);
  width: 40px;
  transition: transform 0.3s linear;
}
.expand-block--is-collapsible .expand-block__list {
  transition: max-height 0.3s ease-in-out;
  max-height: 370px;
  overflow: hidden;
}
@media only screen and (min-width: 1280px) {
  .expand-block--is-collapsible .expand-block__list {
    max-height: 520px;
  }
}
.expand-block.js-expand .expand-block__list {
  max-height: 1600px;
}
.expand-block.js-expand .btn-expand::after {
  transform: rotate(180deg);
}
