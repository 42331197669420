// Format sword
.advertisement {
    &-container {
        margin-top: 56px;
        aspect-ratio: 1568 / 316;
        position: relative;
    }

    &-content {
        &.sword,
        &.sword_display {
            .advertisement-media {
                width: 200px;
                overflow: hidden;
            }
        }

        &.sword,
        &.sword_display,
        &.sword_native {
            .advertisement-product {
                width: 84%;
                padding: 0 8px;

                .splide__pagination--ltr {
                    display: none;
                }

                .product_pub-card {
                    display: flex;
                    flex-direction: column;

                    .product_pub-image {
                        .visual {
                            padding: 8px;
                            height: 150px;
                            
                            .image {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .product_pub-bottom {
                        display: block;
                        padding: 0 16px;
                        text-align: left;
                        text-decoration: inherit;
                        height: inherit;

                        .product_pub-details {
                            min-height: 60px;

                            .name {
                                margin-bottom: 0;
                                width: auto;
                            }
                        }

                        .product_pub-info {
                            display: flex;
                            justify-content: space-between;
                            position: relative;
                            align-items: flex-start;
                            margin-bottom: 0;
                            min-height: 40px;

                            .product_pub-cta {
                                position: relative;
                                align-self: flex-start;
                                bottom: 0;
                                grid-area: 4 / 1 / 5 / 2;
                                display: flex;
                                flex-flow: row nowrap;
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }
        }

        &.sword_native {
            .advertisement-product {
                width: 100%;
            }
        }
    }
}