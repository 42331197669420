.expand-block {
    &__section {
        margin: 20px 0;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: 160px;
        gap: 30px;
        justify-content: space-evenly;
        padding: 10px;
        margin: 0 -10px;

        @media @desktopXL {
            grid-template-columns: repeat(6, 1fr);
        }

        @media @desktop {
            grid-auto-rows: 230px;
        }
    }

    &__wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        background: var(--white);
        border-radius: 5px;
        box-shadow: 0 0 10px var(--overlay-light);
        overflow: hidden;
    }

    &__item-node {
        width: 100%;
        height: 100%;
        padding: 10px;
        position: relative;
    }

    &__item-flip {
        transition: .6s;
        transform-style: preserve-3d;

        &:hover {
            transform: translateY(calc(-100%));
        }
    }

    .title {
        color: var(--dark-grey);
        font-size: 16px;
        text-align: center;
        font-family: @fontBold;
        text-decoration: none;
    }

    &__sublinks {
        position: absolute;
        top: 100%;
        transform: translateY(10px);
        padding: 10px;
        width: 100%;
        left: 0;
        height: 100%;

        .title {
            text-align: left;
        }
    }

    &__sublink-item {
        .sublink {
            display: block;
            font-size: 14px;
            text-decoration: none;
            border-bottom: 1px solid var(--light-grey);
            margin-bottom: 5px;
            padding: 5px;
            color: var(--dark-grey);
        }

        &:last-child {
            .sublink {
                border-bottom: 1px solid transparent;
            }
        }
    }


    .picture_wrapper {
        height: 100%;
        display: flex;
        margin: 0 auto;
        background-color: var(--light-grey);
        border-radius: 3px;
        overflow: hidden;
        max-height: 112px;

        @media @desktop {
            max-height: 182px;
        }

        .picture {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .link {
        text-decoration: none;
        margin: 0 0 20px;
    }

    &__wrapper--has-title {
        .link {
            margin: 10px 0;
        }
    }

    .btn-expand {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding-left: 0;
        font-family: @fontBold;
        font-size: 16px;
        color: var(--blue);
        margin: auto;
        line-height: 15px;

        &::after {
            content: url(../../../version_common/images/pictos/arrow-blue-down.svg);
            width: 40px;
            transition: transform .3s linear;
        }
    }

    &--is-collapsible {
        .expand-block__list {
            transition: max-height 0.3s ease-in-out;
            max-height: 370px;
            overflow: hidden;

            @media @desktop {
                max-height: 520px;
            }

        }
    }

    &.js-expand {
        .expand-block__list {
            max-height: 1600px;
        }

        .btn-expand::after {
          transform: rotate(180deg);
        }
    }
}