.bloc-banner-pub {
    height: auto;
    margin: 24px auto;
    padding: 0;
    display: none;
    text-align: center;

    .bloc-adv-title {
        font-size: 9px;
        text-align: center;
        padding: 5px 0;
    }
}
